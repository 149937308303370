import React from 'react';
import type { FC } from 'react';
import { css } from '@emotion/react';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

const Page: FC = () => (
  <Layout>
    <SEO
      title="献血ドナー募集中"
      description="献血ドナーを募集中です。"
      keywords="献血ドナー"
    />
    <article css={pageBaseStyle}>
      <h1>献血のお願い </h1>
      <div css={skewBorder}></div>
      <section>
        <p css={emphasis}>
        救える命があります。
        </p>
      </section>
      <section>
        <h2>献血ドナーの必要性</h2>
        <p>当院では、様々な悪性腫瘍に苦しむ犬・猫たちの手術や抗がん剤治療を行っています。 これらの治療を行うにあたり「貧血」の状況によっては、「輸血」が必要になることがありますが、獣医療では人のような献血システムや血液バンクがありません。そのため尊い命を救うためには献血ドナーのご協力が必要です。「まずは話しを聞いてみたい」などでも構いませんので、お気軽にご連絡ください。 皆様の愛犬・愛猫が多くの命を救います。
        </p>
      </section>
      <section>
        <h2>ドナー登録の条件</h2>
        <div css={consultationHours}>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>犬</th>
                <th>猫</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>年齢</td>
                <td colSpan={2}>１～7歳</td>
              </tr>
              <tr>
                <td>性別</td>
                <td colSpan={2}>オス　/　メス（妊娠出産経験がないこと）</td>
              </tr>
              <tr>
                <td>体重</td>
                <td>10㎏以上</td>
                <td>4㎏以上</td>
              </tr>
              <tr>
                <td>予防</td>
                <td>フィラリア予防<br />混合／狂犬病ワクチン<br />ノミダニ予防</td>
                <td>混合ワクチン<br />ノミダニ予防</td>
              </tr>
              <tr>
                <td>生活環境</td>
                <td>屋内／屋外飼育どちらも可能</td>
                <td>完全室内飼育</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section>
        <h2>ドナー登録の特典</h2>
        <p>実際に献血にご協力いただいた愛犬・愛猫さんには以下の特典がございます。</p>
        <p>
          <ul>
            <li><FontAwesomeIcon icon={faCheck} color={'#496db7'} css={checkList}/>年に2回の全身健康診断（1回30,000円相当）を無料で受けていただけます</li>
            <li><FontAwesomeIcon icon={faCheck} color={'#496db7'} css={checkList} />愛犬・愛猫さん用おやつをプレゼントいたします</li>
            <li><FontAwesomeIcon icon={faCheck} color={'#496db7'} css={checkList} />当院スタッフ一同　全力で感謝いたします</li>
          </ul>
        </p>
      </section>
      <section>
        <h2>ドナー登録の流れ</h2>
        <ol css={registrationProcess}>
          <li>
            <p>・STEP.1　献血ドナー登録　お申込み　→　当院にお電話ください</p>
          </li>
          <li>
            <p>・STEP.2　来院　カルテの作成</p>
          </li>
          <li>
            <p>・STEP.3　各種検査　→　身体検査・血液型判定・血液検査など</p>
          </li>
          <li>
            <p>・STEP.4　ドナー登録完了　→　各種検査に問題がなければ登録完了です</p>
          </li>
        </ol>
      </section>
      <section>
        <h2>献血当日～ご帰宅までの流れ</h2>
        <p>
          <ul>
            <li>
              <p css={bloodDonationProcessTitle}><FontAwesomeIcon icon={faCheck} color={'#496db7'} css={checkList} />当院からお電話いたします</p>
              <p css={bloodDonationProcessDesc}>献血が必要な際に、当院からドナーの皆様にお電話いたします。来院可能な日時をご相談させてください。</p>
            </li>
            <li>
              <p css={bloodDonationProcessTitle}><FontAwesomeIcon icon={faCheck} color={'#496db7'} css={checkList} />献血当日</p>
              <p css={bloodDonationProcessDesc}>献血当日は朝食抜き、飲水はOKです。予約した時間にご来院ください。</p>
            </li>
            <li>
              <p css={bloodDonationProcessTitle}><FontAwesomeIcon icon={faCheck} color={'#496db7'} css={checkList} />献血採血の実施</p>
              <p css={bloodDonationProcessDesc}>採血は頚静脈（首の血管）や後肢の血管から行います。採血部位の毛刈り、消毒を行います。採血終了後は皮下点滴を行い、状態に異常がないことを確認した後にご帰宅となります。</p>
            </li>
          </ul>
        </p>
      </section>
      <section>
        <p css={emphasis}>献血ドナー登録の手続き、詳しいご説明は当院で直接行いますのでお気軽にご連絡ください。</p>
        <p css={tel}>
          <FontAwesomeIcon icon={faPhone} color={'#496db7'} />
            <Link to="">TEL 03-6284-0450</Link>
        </p>
      </section>
    </article>
  </Layout>
);
export default Page;

const pageBaseStyle = css`
  padding: 1rem 4rem;

  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 1.3rem;
  }

  section {
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
    margin-top: 2rem;
  }

  h2 {
    border-left: solid 0.8rem #80d8ff;
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    clear: both;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  ul li {
    padding-left: 1em;
    text-indent: -1em;
  }
`;

const skewBorder = css`
  width: 50%;
  height: 0.5rem;
  margin: 1rem auto 0;
  background-color: #80d8ff;
  background-image: repeating-linear-gradient(
    -45deg,
    #fff,
    #fff 7px,
    transparent 0,
    transparent 14px
  );

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const emphasis = css`
  font-size: 1.5rem;
  font-weight: bold;
  color: #e66a6a;
`;

const consultationHours = css`
  table {
    border-collapse: collapse;
    text-align: center;
    table-layout: fixed;
    width: 70%;

    @media (max-width: 560px) {
      width: 100%;
    }
  
    thead th {
      padding: 0 1rem 1rem;
      border-left: 1px solid #666;
    }
    thead th:first-child {
      border-left: none;
    }
    tbody td {
      padding: 0.75rem 1rem 0.75rem;
      border-top: 1px solid #666;
      border-left: 1px solid #666;
    }
    tbody td:first-child {
      border-left: none;
    }
  }
`;

const registrationProcess = css`
  font-size: 1.3rem;
`;

const tel = css`
  font-size: 1.5rem;
  color: #496db7;
  letter-spacing: .2rem;
  padding: .2rem;
  margin: .5rem;

  a {
    padding-left: 1rem;
  }
`;

const checkList = css`
  margin-right: .5rem;
`;

const bloodDonationProcessTitle = css`
  font-size: 1.3rem;
`;

const bloodDonationProcessDesc = css`
  padding-left: 1.3rem;
  text-indent: 0; // pageBaseStyleと重複しているため打ち消し。ちょっと微妙だが整理する時間がないためこのまま。
`;
